import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import styles from './intMent.module.css'
import ias from '../Assets/1.webp'
import { useEffect } from 'react'
function Wisdom360() {
    useEffect(() => {
        window.scroll(0, 0);
      }, []);
  return (
    <>
        <Header />
        <div className={styles.main_neet_rep}>
            <div className={styles.main_neet_rep_flex}>
                <div className={styles.main_neet_rep_left_div}>
                    <div className={styles.main_neet_rep_left_div_text}>
                        <h1 className='pt-2 text-4xl font-medium leading-tight text-primary' style={{ color: '#BC631C', fontFamily: 'Source Sans Pro',margin:'0',paddingBottom:'1rem',textAlign:'left'}}>Amrita Edge Wisdom 360 - Civil Service Coaching- Level 2 </h1>
                        <p>Through a thoughtfully planned and holistic approach, Wisdom360 aims to sustain the momentum generated by the IAS foundation course and continues the transformation journey, the students have embarked on. Covering a broad spectrum of subjects and contemporary topics, the programme seeks to widen the knowledge base of the students, simultaneously enhancing their aptitude and skills, so that they become well-rounded individuals adept at facing any challenge in life.</p>
                    </div>
                </div>
                <div className={styles.main_neet_rep_right_div}>
                    <img src={ias}></img>
                </div>
            </div>
        </div>
        <div className={styles.details_neet_rep}>
            <div className={styles.details_neet_rep_inner}>
                <h1 className='pt-2 text-4xl font-medium leading-tight text-primary' style={{ color: '#BC631C', fontFamily: 'Source Sans Pro',margin:'0',paddingBottom:'1rem',textAlign:'left'}}>Details At a Glance</h1>
                <ul>
                    <li>Analytical skills to understand the socioeconomic& political environment.</li>
                    <li>Real-life application of knowledge</li>
                    <li>Focus on current affairs and skill-based sessions</li>
                    <li>Expert guidance for making career choices.</li>
                    <li>Exposure to a well-curated array of topics, every week.</li>
                    <li>Master lateral thinking.</li>
                    <li>Enhance time management & decision-making skills</li>
                    <li>Improve analytical ability.</li>
                </ul>
            </div>
        </div>
        <Footer />
    </>
  )
}
export default Wisdom360